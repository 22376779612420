import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"

const AgreementPage = () => {
  return (
    <Layout>
      <Seo
        title="Regulamin szkoleń"
        description={`Regulamin szkoleń księgowości i rachunkowości`}
      />
      <ContentContainer>
        <TextContainer>
          <h1>Regulamin szkoleń</h1>
          <ol>
            <li>
              Organizatorem szkoleń jest firma „Posterus” Bożena Krasnodębska z
              Wrocławia nr wpisu do ewidencji działalności gospodarczej 210004
              nr NIP 8941732722.
            </li>
            <li>
              Termin, nazwa, program szkolenia umieszczone są na stronie
              internetowej organizatora www.kursyfinansowe.com.pl
            </li>

            <li>Liczbę uczestników kursu ustala się na maksimum 12 osób.</li>
            <li>
              O przyjęciu na szkolenie decyduje kolejność zgłoszeń, po
              wyczerpaniu liczby wolnych miejsc organizator zamyka listę
              uczestników kursu.
            </li>
            <li>
              Organizator zapewnia wykwalifikowaną kadrę , posiadającą zarówno
              wiedzę teoretyczną jak i doświadczenie praktyczne.
            </li>
            <li>
              Warunkiem uczestnictwa w szkoleniu jest wypełnienie i przesłanie
              formularza zgłoszeniowego dla osób fizycznych lub dla firm
              umieszczonego na stronie internetowej organizatora oraz dokonanie
              płatności po otrzymaniu e-maila z potwierdzeniem przyjęcia
              zgłoszenia.
            </li>
            <li>
              Dane osobowe zawarte w zgłoszeniu służą tylko celom rekrutacji,
              nie będą w żaden sposób przetwarzane, ani wykorzystywane przez
              organizatora.
            </li>
            <li>
              Ceny za poszczególne szkolenia oraz promocje umieszczone są na
              stronie internetowej organizatora.
            </li>
            <li>
              Płatność za kurs następuje w dwóch ratach przelewem na podane w
              e-mailu konto bankowe w następujący sposób : pierwsza zaliczka w
              kwocie 300zł z terminem płatności do trzech dni od daty otrzymania
              e-maila z potwierdzeniem przyjęcia zgłoszenia, termin płatności
              drugiej raty upływa na cztery dni przed terminem rozpoczęcia
              kursu, prosimy o umieszczenie na przelewie nazwy szkolenia oraz
              nazwisk uczestników.
            </li>
            <li>
              Cena kursu obejmuje: uczestnictwo, materiały szkoleniowe, egzamin,
              zaświadczenie o ukończeniu kursu.
            </li>
            <li>
              Wpływ zaliczki lub całości opłaty za szkolenie potwierdzane jest
              przez organizatora poprzez przesłanie emaila z potwierdzeniem
              wpłaty.
            </li>
            <li>
              Organizator dwa dni przed datą rozpoczęcia kursu przesyła
              uczestnikom szkolenia drogą elektroniczną harmonogram zajęć.
            </li>
            <li>
              Uczestnik może wycofać w całości zapłaconą zaliczkę za szkolenie
              nie później niż 14 dni przed datą rozpoczęciem kursu.
            </li>
            <li>
              W przypadku rezygnacji ze szkolenia w terminie krótszym niż 14 dni
              przed rozpoczęciem kursu uczestnikowi nie przysługuje zwrot
              wpłaconej kwoty.
            </li>
            <li>
              Nieobecność na szkoleniu nie zwalnia uczestnika z obowiązku
              zapłaty za szkolenie.
            </li>
            <li>
              Organizator zastrzega sobie prawo do przesunięcia terminu lub
              odwołania szkolenia jeżeli nie zostanie zapisana minimalna
              wymagana liczba uczestników lub z innych przyczyn organizacyjnych.
            </li>
            <li>
              W przypadku przesunięcia terminu lub odwołania szkolenia przez
              Organizatora, każdy klient otrzymuje taką informację drogą
              telefoniczną lub pocztą elektroniczną. Wpłacone kwoty zwracane są
              niezwłocznie na rachunek bankowy, z którego dokonana była
              płatność.
            </li>
          </ol>
        </TextContainer>
      </ContentContainer>
    </Layout>
  )
}

export default AgreementPage
